import React from 'react';
import './Footer.css';

function Footer() {
  return (
    <footer className="footer">
      <div className="container">
        <div
          className="social"
          style={{ display: 'flex', justifyContent: 'flex-end' }}
        >
          <a
            href="https://twitter.com/sajinshrestha"
            rel="noopener noreferrer"
            target="_blank"
          >
            Twitter
          </a>
          <a
            href="http://instagram.com/golfanatic"
            rel="noopener noreferrer"
            target="_blank"
          >
            <span>&nbsp;</span>Instagram
          </a>
          <a
            href="http://www.linkedin.com/in/sajinshrestha"
            rel="noopener noreferrer"
            target="_blank"
          >
            <span>&nbsp;</span>Linkedin
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
