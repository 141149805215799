import React, { Component } from 'react';
import Footer from './Footer';
import './App.css';

function App() {
  return (
    <div className="App">
      <main role="main" className="main">
        <code className="lead">Hello! I&#39;m</code>
        <h1>SAJIN DAS SHRESTHA</h1>
        <code className="lead">I like to code and make things obvious.</code>
        <div style={{ marginTop: 20 }}>
          <img
            className="img-polaroid img-circle"
            src={process.env.PUBLIC_URL + '/images/life.jpg'}
            alt="Life is Good"
          />
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default App;
